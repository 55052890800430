import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {
  IconButton,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import StaffSelect from './StaffSelect';
import useUpcomingRequests from '../hooks/useUpcomingRequests';
import LoadingSpinner from './LoadingSpinner';
import { Alert } from '@material-ui/lab';
import { useRouter } from 'next/router';
import formatStaffName from '../utils/formatStaffName';
import Link from 'next/link';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    marginTop: 10,
  },
  subheader: {
    fontSize: 18,
  },
}));

function UpcomingRequests({ userId }) {
  const router = useRouter();
  const classes = useStyles();
  const upcomingRequests = useUpcomingRequests(userId);
  if (upcomingRequests.isLoading) return <LoadingSpinner />;
  if (upcomingRequests.isError)
    return <p>Error: {upcomingRequests.error.message}</p>;
  if (!upcomingRequests.data.length)
    return (
      <Alert severity="info" style={{ marginTop: 15 }}>
        No upcoming requests were found
      </Alert>
    );
  return (
    <List className={classes.root} subheader={<li />}>
      {upcomingRequests.data?.map((item) => (
        <li key={item.date} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader
              color="primary"
              disableGutters
              className={classes.subheader}
            >
              {item.date}
            </ListSubheader>
            {item.requests.map((request, index) => {
              const requestedBy = request.delegatedTo
                ? `${formatStaffName(
                    request.delegatedTo
                  )}, on behalf of ${formatStaffName(request.requestedBy)}`
                : formatStaffName(request.requestedBy);
              return (
                <ListItem
                  component={Link}
                  key={request.id}
                  button
                  divider={index !== item.requests.length - 1}
                  href={`/cover/${request.id}/view`}
                  target="_blank"
                >
                  <ListItemText
                    primary={request.entry.class.name}
                    secondary={`Period ${
                      request.entry?.timetablePeriod
                        ? request.entry.timetablePeriod.name.split(':')[1]
                        : `${request.entry.startDateTime.toLocaleString()} - ${request.entry.endDateTime.toLocaleString()}`
                    } \u2022 Created by ${requestedBy}`}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              );
            })}
          </ul>
        </li>
      ))}
    </List>
  );
}

export default function BrowseCoverDialog({ isOpen, handleClose }) {
  const [selectedStaff, setSelectedStaff] = useState(null);
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-slide-title" onClose={handleClose}>
        Browse cover work
      </DialogTitle>
      <DialogContent dividers>
        <StaffSelect
          value={selectedStaff}
          onChange={setSelectedStaff}
          label="Search by staff member"
          clearOnEscape
        />
        {/* show all upcoming cover by default, filter by staff member if selected */}
        <UpcomingRequests userId={selectedStaff?.id} />
      </DialogContent>
    </Dialog>
  );
}

const DialogTitle = withStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
